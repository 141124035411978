import Typography from "@mui/material/Typography";
import ArrowOutward from "@mui/icons-material/ArrowOutward";

import { MenuItemPrimary } from "@components/Common/Menu/MenuItemPrimary";
import MenuItemMobile from "@components/Common/Menu/MenuItemMobile";


export const renderMainMenu = (menu) => menu?.map((item) => <MenuItemPrimary key={item.id} item={item} /> )

export const renderMainMenuMobile = (menu, close, parentOpen) => menu?.filter(el => el.field_layout !== 'card').map((item, index) => <MenuItemMobile key={index} item={item} close={close} parentOpen={parentOpen}/> )

export const renderFooterMenu = (menu) => {
  return (

    menu.map((item, index) =>  {
      
      let external = item.options.external && !item.url.includes(process.env.NEXT_PUBLIC_BASE_URL);
      
      return(
        <Typography key={index} variant="bodyM" component="a" href={item.url} target={external ? '_blank' : '_self'} mb={4}>
          {item.title} {external && <ArrowOutward />}
        </Typography>
      )
    })
  )
}

export const renderCopyrightMenu = (menu) => menu?.map((item, index) => (
  <a key={index} href={item.url} title={item.title}>
    {item.title}
  </a>
))