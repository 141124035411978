import Logo from '@components/UI/Logo/Logo';
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";


import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import Close from "@mui/icons-material/Close";

import styles from "@components/Common/Header/Header.module.scss";
import { MenuItemModel } from './Menu.model';

interface MenuItemProps {
  item: MenuItemModel;
  close: () => void;
  parentOpen: boolean;
}

const MenuItemMobile: React.FC<MenuItemProps> = ({item, close, parentOpen }) => {
  const { t } = useTranslation();
  const hasChild: boolean = item?.items?.length > 0;
  const [open, setOpen] = useState<boolean>(false);
  const [items, setItems] = useState(item?.items?.filter(el => el.field_layout !== 'card'))
  
  const goNext = () => {
    setOpen(true);
  }

  const goBack = () => {
    setOpen(false);
  }

  const closeMenu = () => {
    goBack();
    close();
  }

  useEffect(() => {
    if(item.field_layout === "expanded") {
      const reducedItems = item?.items
      .filter(el => el.field_layout === 'column')
      .reduce((accumulator, current) => {
        if (current.field_layout === "column" && Array.isArray(current.items)) {
          return accumulator.concat(current.items);
        }
        return accumulator;
      }, [])
      setItems(reducedItems)
    } else {
      setItems(item?.items?.filter(el => el.field_layout !== 'card'))
    }
  }, [item])

  return (
    <>
      <ListItem disablePadding>
        <ListItemButton
          sx={{ px: 0 }}
          {...hasChild ? {
            component: "div",
            onClick: goNext
          } : {
            component: Link,
            href: item.url
          }}
        >
          <ListItemText primary={item.title} />

          {hasChild && (
            <ListItemIcon sx={{justifyContent: 'flex-end'}}>
              <ArrowForwardIos fontSize="small" color="primary" />
            </ListItemIcon>
          )}

        </ListItemButton>
      </ListItem>
      
      {(hasChild && parentOpen) && (
        <Drawer
          open={open}
          ModalProps={{ keepMounted: false }}
          SlideProps={{ direction: 'left' }}
          onClose={closeMenu}
          sx={{
            display: { xs: 'block', xl: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: '100%' },
          }}
        >
          <Box>
            <Container maxWidth={false} className={styles.header_drawer}>

              <Box className={styles.logo} display='flex' alignItems='center' justifyContent='space-between'>
                <Link href='/'><Logo /></Link>
                <IconButton
                  color="inherit"
                  aria-label="close drawer"
                  edge="end"
                  onClick={closeMenu}
                >
                  <Close />
                </IconButton>
              </Box>

              <Box sx={{ mt: 24 }} className={styles.mainMenuPrimary}>

                <ListItem disablePadding>
                  <ListItemButton sx={{ p: 0 }} component="a" onClick={goBack} >
                    <ListItemIcon sx={{minWidth: 32}}>
                      <ArrowBackIos fontSize="small" color="primary" />
                    </ListItemIcon>
                    <ListItemText primary={item.title} primaryTypographyProps={{fontWeight: 500}} />
                  </ListItemButton>
                </ListItem>              
                
                <Link href={item.url}>
                  <Typography variant="bodyL" color="primary" fontWeight={500} ml={32} mb={4} display="block">{t("discoverMore")}</Typography>
                </Link>

                <List>
                  {items?.map((el, i) => 
                    item.field_layout === "expanded"
                      ? <MenuItemMobileExpanded key={el.id} item={el} />
                      : <MenuItemMobile key={el.id} item={el} close={close} parentOpen={parentOpen} />
                  )}
                </List>
              </Box>

              <Button
                className={styles.buttonDrawer}
                id="header-mobile-contact-us"
                variant="contained"
                href={t("contact_us_url")}
              >{t('header.button')}</Button>

            </Container>
          </Box>
        </Drawer>
      )}
    </>
  )
}

const MenuItemMobileExpanded: React.FC<{item: MenuItemModel}> = ({item}) => {

  const isParent: boolean = item.field_layout === 'icon'

  return(
    <>
      <ListItem
        key={item.id}
        disablePadding
        className={isParent ? styles.dropdownTitle : null} >
        <ListItemButton
          sx={{ px: 0 }}
          component={Link}
          href={item.url}
        >
          <ListItemText primary={item.title} />
        </ListItemButton>
      </ListItem>

      {item?.items?.length && <List disablePadding>
        {item.items?.map(sub => {
          return (
            <ListItem key={sub.id} disablePadding>
              <ListItemButton
                sx={{ px: 0 }}
                component={Link}
                href={sub.url}
              >
                <ListItemText primary={sub.title} />
              </ListItemButton>
            </ListItem>
          )
        })}
      </List>}
    </>
  )
}

export default MenuItemMobile