import {absoluteUrl} from "@lib/utils";

export const getThumbnailUrl = (field) => {
  const thumbnail : string =  field && field.thumbnail && field.thumbnail.uri ? absoluteUrl(field.thumbnail.uri.url) : null;
  return thumbnail
};

export const getThumbnailAlt = (field) => {
  const thumbnail : string =  field?.thumbnail ? field?.thumbnail.resourceIdObjMeta.alt : null;
  return thumbnail
};


export const getDocumentUrl = (field) => {
  const document : string =  field?.field_media_document?.uri ? absoluteUrl(field.field_media_document.uri.url) : null;
  return document
};

export const getVideoUrl = (field) => {
  const video : string =  field?.field_media_video_file?.uri ? absoluteUrl(field.field_media_video_file.uri.url) : null;
  return video
};

// TODO: rivedere sulle card
export const PLACEHOLDER_IMAGE = {
  "node--implants": "/assets/images/img-placeholder-01.jpg",
  "node--article": "/assets/images/img-placeholder-01.jpg",
}

export const getIconName = (field) => {
  let iconName = field ? field?.name?.replace(/\.[^/.]+$/, "") : null;
  return iconName
}

export const getImageData = (field): { src: string, alt: string, width: number, height: number } => {

  if (!field) return;

  const src: string = getThumbnailUrl(field);
  const alt: string | null = getThumbnailAlt(field);
  const width: number = field?.field_media_image?.resourceIdObjMeta?.width;
  const height: number = field?.field_media_image?.resourceIdObjMeta?.height;

  return {
    src, alt, width, height
  }
}

export const getUrlAttributes = (field) => {
  return {
    href: field?.url,
    target: field?.options.attributes?.target || '_self',
    title: field?.title,
    rel: field?.rel,
  }
}